import React from 'react'
import './PricingSection.scss'
import PricingCard from "./card/PricingCard";
import essential from '../../../assets/images/essential.png'
import advanced from '../../../assets/images/advanced.png'
import complete from '../../../assets/images/complete.png'
import {Pricing} from "../../models/pricing";

function PricingSection() {
    const pricingList: Pricing[] = [
        new Pricing({
            imagePath: essential,
            title: "Essential",
            subTitle: "Algo API for order dispatch",
            price: 0.04,
            features: ["Both planned and on demand deliveries", "First 10,000 orders are free"]
        }),
        new Pricing({
            imagePath: advanced,
            title: "Advanced",
            subTitle: "Mobile app for Couriers",
            price: 0.06,
            features: ["Both planned and on demand deliveries", "First 10,000 orders are free", "Algo API for order dispatch"]
        }),
        new Pricing({
            imagePath: complete,
            title: "Complete",
            subTitle: "Dispatcher web platform for tracking orders",
            price: 0.1,
            features: ["Both planned and on demand deliveries", "First 10,000 orders are free", "Algo API for order dispatch", "Courier management", "Mobile app for Couriers"]
        })
    ];

    return (
        <>
            <div className="pricing section">
                <span className='pricing-title section-title'>Pricing</span>
                <div className='cards-container'>
                    {pricingList && pricingList.map((el: Pricing, index) =>
                        <PricingCard title={el.title} subTitle={el.subTitle} image={el.imagePath} price={el.price}
                                     features={el.features} key={index}/>)}
                </div>
            </div>
        </>
    )
}

export default PricingSection;