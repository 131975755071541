import React from 'react'
import {withRouter} from 'react-router-dom'
import './JobDescription.scss'
import {History} from 'history'
import {Link} from 'react-router-dom'
import Header from "../../components/header-component/Header";
import Footer from "../../components/footer-component/Footer";
import {JoinFields} from '../../models/join'
import {jobData} from "../../models/jobdata";
import Input from "../../components/input-component/Input";
import Dropzone from "../../components/dropzone-component/Dropzone";
import axios from 'axios'
import chevron from '../../../assets/images/chevron-left.svg'
import plus from '../../../assets/images/plus.svg'
import check from '../../../assets/images/check.svg'
import Jobs from "../../context/JobData";
import {Checkbox} from "@material-ui/core";
import {privacyURL, termsURL} from "../../../urls";
import * as emailJs from 'emailjs-com'
import * as Sentry from "@sentry/react";

interface AppProps {
    history: History,
    onFilesAdded: any,
    match: any
}

class JobDescription extends React.Component<AppProps, any> {

    params: any

    constructor(props: AppProps) {
        super(props)
        this.state = {
            error: new JoinFields({}),
            values: new JoinFields({}),
            jobData: new jobData({}),
            files: [],
            agreedTerms: true,
            loading: true,
            isFormSent: false,
            hasAdditionalLink: false,
        }

        this.onChange = this.onChange.bind(this)
        this.addFile = this.addFile.bind(this)
        this.removeFile = this.removeFile.bind(this)
        this.submitForm = this.submitForm.bind(this)
        this.hasLinks = this.hasLinks.bind(this)
        this.applyForm = React.createRef();
    }

    applyForm: any;

    async componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('popstate', (event) => {
            this.props.history.push('/join-us')
        }, false);
        try {
            const response = await fetch(`${process.env.REACT_APP_RAPID_DONKEY_WEBSITE_API}/mail`);
            const json = await response.json();
            this.setState({mailData: json.mailData[1]});
        } catch (error) {
            console.log(error);
        }
    }

    appendInput() {
        let links = [...this.state.values.links]
        links.push("")
        this.setState((prevState: any) => {
            return {values: {...prevState.values, links: links}}
        })
    }

    onChange(e: any, name: string) {
        if (name.includes('links')) {
            const linksData = name.split('_')
            let newContactObj = {...this.state.values}
            newContactObj[linksData[0]][linksData[1]] = e.target.value
            this.setState({values: {...newContactObj}})
        } else {
            let newContactObj = {...this.state.values}
            newContactObj[name] = e.target.value
            let errors = this.validateForm(newContactObj);
            this.setState((prevState: any) => {
                return {values: newContactObj, error: {...prevState.error, [name]: errors.error[name]}}
            })
        }
    }

    addFile(file: any, name: string) {
        this.setState((prevState: any) => {
            return {values: {...prevState.values, [name]: file}, error: {...prevState.error, [name]: ''}}
        })
    }

    removeFile(name: string) {
        this.setState((prevState: any) => {
            return {
                values: {...prevState.values, [name]: null},
                error: {...prevState.error, [name]: 'This field is required'}
            }
        })
    }

    validateForm = (receivedObj = null) => {
        let objToBeVerified = receivedObj ? receivedObj : {...this.state.values}
        let errorObj = {...this.state.error}

        for (const [name, value] of Object.entries(objToBeVerified)) {
            // Skip required values
            if (!["linkedin", "website", "links", "agreedTerms", "changeOfView", "careerObjectives"].includes(name)) {
                if (!value) {
                    errorObj[name] = 'This field is required'
                } else {
                    errorObj[name] = ''
                }
                switch (name) {
                    case 'phone':
                        let phoneReg = /^\d+$/
                        if (!phoneReg.test(value as string) && value)
                            errorObj[name] = 'Please enter a valid phone number'
                        break
                    case 'email':
                        let emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        if (!emailReg.test(value as string) && value)
                            errorObj[name] = 'Please enter a valid email address'
                        break;
                }
            }
        }
        let hasError = false;
        for (const [name, value] of Object.entries(objToBeVerified)) {
            if (!["linkedin", "website", "links", "agreedTerms", "changeOfView", "careerObjectives"].includes(name) && errorObj[name] !== "") {
                hasError = true
            }
        }
        return {error: errorObj, hasError: hasError}
    }

    validateData = (e: any, name: string) => {
        let errors = this.validateForm();
        this.setState({
            error: {...this.state.error, [name]: errors?.error[name]}
        })
    }

    submitForm(e: any) {
        e.preventDefault();

        let errors = this.validateForm();
        if (errors?.hasError) {
            this.setState({error: errors.error})
            window.scroll(0, this.applyForm.current.offsetTop - 100)
        } else {
            e.preventDefault();
            const templateParams = {
                from_name: this.state.values.lastName + ' ' + this.state.values.firstName + " (" + this.state.values.email + ")",
                name: this.state.values.lastName + ' ' + this.state.values.firstName,
                email: this.state.values.email,
                phone: this.state.values.phone,
                earliestStartDate: this.state.values.startDate,
                linkedin: this.state.values.linkedin,
                website: this.state.values.website,
                jobId: this.props.match.params.id
            }

            emailJs
                .send(this.state.mailData.service_id, this.state.mailData.template_id, templateParams, this.state.mailData.user_id)
                .then((response) => {
                        console.log('')
                    }, (err) => {
                        Sentry.captureMessage("Failed to send application email. ", err);
                    }
                );


            let formData = new FormData()
            const {resume} = this.state.values
            const {coverLetter} = this.state.values
            formData.append('resume', resume, resume.name)
            formData.append('coverLetter', coverLetter, coverLetter.name)
            formData.append('firstName', this.state.values.firstName)
            formData.append('lastName', this.state.values.lastName)
            formData.append('email', this.state.values.email)
            formData.append('phone', this.state.values.phone)
            formData.append('startDate', this.state.values.startDate)
            formData.append('foundUs', this.state.values.foundUs)
            formData.append('linkedin', this.state.values.linkedin)
            formData.append('website', this.state.values.website)
            formData.append('links', this.state.values.links)
            formData.append('agreedTerms', this.state.agreedTerms)
            formData.append('jobId', this.props.match.params.id)
            formData.append('changeOfView', this.state.values.changeOfView)
            formData.append('careerObjectives', this.state.values.careerObjectives)
            formData.append('applicationDate', new Date().toLocaleString('default', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            }))


            axios.post(`${process.env.REACT_APP_RAPID_DONKEY_WEBSITE_API}/apply`, formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            }).then(
                (response) => {
                },
                (err) => {
                    Sentry.captureMessage("Failed to send application data. ", err);
                }
            );
            this.setState({isFormSent: true})
        }
    }

    hasLinks(additionalLink: any) {
        this.setState({hasAdditionalLink: additionalLink})
    }

    render() {
        return (
            <React.Fragment>
                <Header areButtonsAvailable={true} activeLink={"careers"}/>
                <div className='job-description form-div section'>
                    <Link to='/join-us'>
                        <div className='green-link'>
                            <img src={chevron}/>
                            All positions
                        </div>
                    </Link>
                    <Jobs id={this.props.match.params.id} jobHasLink={this.hasLinks}
                          hasAdditionalLink={this.state.hasAdditionalLink}/>
                    {!this.state.isFormSent ? (
                        <div className='apply-form form' ref={this.applyForm}>
                            <span className='form-title'> Apply for this position </span>
                            <Input
                                className={`apply-input big-size-label ${!this.state.error.firstName ? 'valid' : 'has-error'}`}
                                label='First name'
                                name='firstName'
                                required={true}
                                error={this.state.error.firstName}
                                helperText={this.state.error.firstName}
                                value={this.state.firstName}
                                onChange={this.onChange}
                                onBlur={this.validateData}
                            />

                            <Input
                                className={`apply-input big-size-label ${!this.state.error.lastName ? 'valid' : 'has-error'}`}
                                label='Last name'
                                name='lastName'
                                required={true}
                                error={this.state.error.lastName}
                                helperText={this.state.error.lastName}
                                value={this.state.lastName}
                                onChange={this.onChange}
                                onBlur={this.validateData}
                            />

                            <Input
                                className={`apply-input ${!this.state.error.email ? 'valid' : 'has-error'}`}
                                label='Email'
                                name='email'
                                required={true}
                                error={this.state.error.email}
                                helperText={this.state.error.email}
                                value={this.state.email}
                                onChange={this.onChange}
                                onBlur={this.validateData}
                            />

                            <Input
                                className={`apply-input ${!this.state.error.phone ? 'valid' : 'has-error'}`}
                                label='Phone'
                                name='phone'
                                required={true}
                                error={this.state.error.phone}
                                helperText={this.state.error.phone}
                                value={this.state.phone}
                                onChange={this.onChange}
                                onBlur={this.validateData}
                            />

                            <div className='apply-input'>
                                <label> Resume/ CV *</label>
                                <Dropzone
                                    name={'resume'}
                                    error={this.state.error.resume}
                                    onChange={this.addFile}
                                    onRemove={this.removeFile}
                                    disabled={false}/>
                                {this.state.error.resume ? (<p>{this.state.error.resume}</p>) : null}
                            </div>

                            <div className='apply-input'>
                                <label> Cover letter *</label>
                                <Dropzone
                                    name={'coverLetter'}
                                    error={this.state.error.coverLetter}
                                    onChange={this.addFile}
                                    onRemove={this.removeFile}
                                    disabled={false}/>
                                {this.state.error.coverLetter ? (<p>{this.state.error.coverLetter}</p>) : null}
                            </div>

                            <Input
                                className={`apply-input ${!this.state.error.startDate ? 'valid' : 'has-error'}`}
                                label='What is your earliest possible start date?'
                                name='startDate'
                                required={true}
                                error={this.state.error.startDate}
                                helperText={this.state.error.startDate}
                                value={this.state.startDate}
                                onChange={this.onChange}
                                onBlur={this.validateData}

                            />
                            <Input
                                className={`apply-input ${!this.state.error.foundUs ? 'valid' : 'has-error'}`}
                                label='How did you find out about us?'
                                name='foundUs'
                                required={true}
                                error={this.state.error.foundUs}
                                helperText={this.state.error.foundUs}
                                value={this.state.foundUs}
                                onChange={this.onChange}
                                onBlur={this.validateData}

                            />
                            <Input
                                className='apply-input'
                                label='Linkedin profile'
                                name='linkedin'
                                required={false}
                                value={this.state.linkedin}
                                onChange={this.onChange}
                                onBlur={this.validateData}
                            />

                            <Input
                                className='apply-input'
                                label='Website'
                                name='website'
                                required={false}
                                value={this.state.website}
                                onChange={this.onChange}
                                onBlur={this.validateData}
                            />

                            {this.state.hasAdditionalLink === true ?
                                (<React.Fragment>
                                    {this.state.values.links && this.state.values.links.map((el: string, index: number) =>
                                        <Input className={'apply-input long-label'}
                                               label='Do you have links to any public code samples (e.g. public Github, Bitbucket, pet projects, Kaggle etc)'
                                               name={`links_${index}`}
                                               required={false}
                                               value={this.state.links}
                                               onChange={this.onChange}
                                               onBlur={this.validateData}
                                               key={index}
                                        />)}
                                    <div className='new-link' onClick={() => this.appendInput()}>
                                        <img src={plus} alt={"plus"}/>
                                        Add new link
                                    </div>
                                </React.Fragment>) : null
                            }

                            <Input
                                className={'apply-input long-label'}
                                label='How would you describe (in less than 300 characters) your most important change of view over the world?'
                                name='changeOfView'
                                required={false}
                                value={this.state.changeOfView}
                                onChange={this.onChange}
                                onBlur={this.validateData}
                                multiline={true}
                                rowsMax={4}
                                maxLength={300}
                                showCharCounter={true}
                            />
                            <Input
                                className={'apply-input long-label'}
                                label='How would you describe (in less than 300 characters) your personal career objectives? Please answer in Romanian this time.'
                                name='careerObjectives'
                                required={false}
                                value={this.state.careerObjectives}
                                onChange={this.onChange}
                                onBlur={this.validateData}
                                multiline={true}
                                rowsMax={4}
                                maxLength={300}
                                showCharCounter={true}
                            />
                            <div className='checkbox-container'>
                                <Checkbox
                                    defaultChecked={true}
                                    value={this.state.agreedTerms}
                                    disableRipple
                                    onChange={() => {
                                        this.setState({agreedTerms: !this.state.agreedTerms})
                                    }}
                                />
                                <label>I agree to the <a className='linkTo' href={termsURL}>Terms &
                                    Conditions</a> and <a
                                    className='linkTo' href={privacyURL}>Privacy
                                    Policy</a></label>
                            </div>
                            <div onClick={this.submitForm}
                                 className='button blue xlarge default-hover'> Submit application
                            </div>
                        </div>) : (<div className='success-apply'
                    >
                        <img className='check-img' src={check}/>
                        <span className='section-text'> Thank you for reaching out to us. <br/>
			</span>
                        <Link to='/'>
					<span className='redirect'>
						<img src={chevron}/>
						Go to home page
					</span>
                        </Link></div>)
                    }
                </div>
                <Footer/>
            </React.Fragment>
        )
    }
}

// @ts-ignore
export default withRouter(JobDescription);