import React, {useEffect, useState} from 'react'
import './Article.scss'
import Header from "../../components/header-component/Header";
import Footer from "../../components/footer-component/Footer";
import {request} from "graphql-request";
import {Article as articleType} from "../../models/article";
import chevron from "../../../assets/images/chevron-left.svg";
import chevronActive from "../../../assets/images/chevron-left-active.svg";
import {Link} from "react-router-dom";
import DOMPurify from 'dompurify';

function Article() {
    const [isFocused, setIsFocused]: [boolean, any] = useState(false)
    const [articleId, setArticleId]: [string, any] = useState(window.location.href.slice(31))
    const [articleData, setArticleData] = useState<articleType>()

    function getArticle() {
        const endpoint: string = (process.env.REACT_APP_HYGRAPH_URL as string);
        console.log(endpoint)
        const query = `query getArticle($theId: ID) {
                  article(where: {id: $theId}) {
                    title
                    subtitles
                    image {
                      url
                    }
                    contents {
                      html
                    }
                  }
                }`
        request({
            url: endpoint,
            document: query,
            variables: {theId: articleId}
        }).then((data) => {
            setArticleData(new articleType({
                title: data.article.title,
                image: data.article.image.url,
                subtitles: data.article.subtitles,
                contents: data.article.contents
            }))
        })
    }

    useEffect(() => {
        getArticle()
    }, [])

    return (
        <>
            <Header areButtonsAvailable={true} activeLink={"articles"}/>
            <div className={"section"}>
                <Link to={'/articles'} className={"all-articles-link"}
                      onMouseEnter={() => setIsFocused(true)}
                      onMouseLeave={() => setIsFocused(false)}>
                    <div>
                        {!isFocused && <img src={chevron} className={"chevron"}/>}
                        {isFocused && <img src={chevronActive} className={"chevron"}/>}
                        <span className='text'>All articles</span>
                    </div>
                </Link>
                <div className={"image-container"}>
                    <img src={articleData?.image}/>
                </div>
                <div className={"title"}>{articleData?.title}</div>
                {articleData?.subtitles && articleData.subtitles.map((el: string, index) =>
                    <>
                        <div className={"subtitle"}>{el}</div>
                        <div className={"text"}
                             dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(articleData?.contents[index].html)}}/>
                    </>
                )}
            </div>
            <Footer/>
        </>
    )
}

export default Article;